import { useContext, useRef } from 'react';
import { ProjectContext } from '../store/context/projectContext';
import { CURR_FORMAT } from '../helper/CountryFlag';
import ProjectCurrencyType from '../types/ProjectCurrencyType';
import { useLocation } from 'react-router-dom';

export const formatCurr = (value: number | string, local: string, decimal = 2) => {
    if (value?.toString()?.includes('.')) {
        // If it has a fractional part, format with two digits after the decimal point
        return new Intl.NumberFormat(local, { minimumFractionDigits: decimal }).format(+value);
    }
    // If it's an integer, result will be the whole number
    return new Intl.NumberFormat(local).format(+value);
};
interface LocationState {
    pathname: string;
}

// This function Devide the value with exchange rate and adds currency symbol and comma separator
export const useFormatCurrency = () => {
    const curLocation:LocationState = useLocation();
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    // const exchangeRate = displayCurr?.exchange_rate;
    const exchangeRate = curLocation?.pathname === '/industries' || curLocation?.pathname === '/commodities' ? 1 : displayCurr?.exchange_rate;

    return (value: string) => {
        const currencySymbol = displayCurr?.symbol || '';
        const formattedValue = value && exchangeRate ? (+value) / (+exchangeRate) : +value;
        const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
        const finalValue = formattedValue.toFixed(0);
        return `${currencySymbol}${formatCurr(+finalValue, local)}`;
    };
};

// This function add currency symbol and comma separator to the value passed in
export const useDisplayCurrFormatter = () => {
    const projectCtx = useContext(ProjectContext);
    const displayCurrRef = useRef<ProjectCurrencyType | undefined>();
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    displayCurrRef.current = displayCurr;

    return (value: number | string) => {
        const currencySymbol = displayCurrRef?.current?.symbol || '';
        const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
        return `${currencySymbol}${formatCurr(value, local)}`;
    };
};
