import React, { useState } from 'react';
import userInvites from '../../../queries/invite';
import { useStyles } from './CustomInviteLogin.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import {
    Alert, Box, IconButton, Snackbar, TextField,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';
import InvitationType from '../../../types/InvitationTypes';
import Loader from '../../../components/Loader';
import { PATH_PAGE } from '../../../Routes/path';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function CustomInviteLogin() {
    const classes = useStyles();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const organization = query.get('organization');
    const invitation = query.get('invitation');
    const organizationName = query.get('organization_name');
    const [password, setPassword] = useState('');
    const [openErrorBar, setOpenErrorBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();

    const handleAcceptInviteSuccess = (data: InvitationType) => {
        if (data.acceptInvitation && data.acceptInvitation.error_code) {
            // Show error message in toaster
            setErrorMessage(data.acceptInvitation.error);
            setOpenErrorBar(true);
        } else {
            // Re-direct to auth0 login
            // loginWithRedirect({
            //     authorizationParams: {
            //         organization: organization || '',
            //         invitation: invitation || '',
            //     },
            //     appState: {
            //         returnTo: '/dashboard',
            //     },
            // })
            //     .then((res) => console.log(res))
            //     .catch((err) => console.log(err));
            navigate(PATH_PAGE.invitationAccepted);
        }
    };

    const handleAcceptInviteFailure = () => {
        // Show error message in toaster
        setErrorMessage('Unable to accept invite');
        setOpenErrorBar(true);
    };

    const { mutate, isLoading } = useGQLMutation(
        'AcceptInvite',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-useless-escape
        userInvites.ACCEPT_INVITATION(
            'login',
            invitation || '',
            organization || '',
            email || '',
            password,
        ),
        {
            onSuccess: handleAcceptInviteSuccess,
            onError: handleAcceptInviteFailure,
        },
        '/checkinvitation',
    );

    const handleCustomLogin = () => {
        // Password validation
        if (!password) {
            setPasswordError(true);
        } else if (!email) {
            setErrorMessage('Invalid url, email not provided');
            setOpenErrorBar(true);
        } else {
            mutate({});
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <div className={classes.logoSection}>
                <img
                    src="https://pcu-web.csi-infra.com/static/media/logo.f83acbb9fa7ecde108679773c57f2c6d.svg"
                    alt="logo.svg"
                    className={classes.backgroundImg}
                />
            </div>
            <div className={classes.wrapper}>
                <div className={classes.contentWrapper}>
                    <div className={classes.contentLeftSection}>
                        <h2 className={classes.loginHeaderText}>PCU System</h2>
                        <p className={classes.loginParagraphText}>
                            {' '}
                            Global Cost Database and Estimating Platform for Engineering Professionals, Project Managers, and Cost Analysts
                            {' '}
                        </p>
                    </div>
                    <div className={classes.loginBox}>
                        <p className={classes.centerDiv}>
                            Accept your invitation to join
                            {' '}
                            <Tooltip title={organizationName}>
                                <span>{organizationName}</span>
                            </Tooltip>
                        </p>
                        <div className={classes.loginBoxContainer}>
                            <TextField
                                className={`${classes.marginBottom} ${classes.disabledTextField}`}
                                type="email"
                                placeholder="Email"
                                name="uname"
                                id="email"
                                value={email || ''}
                                disabled
                                fullWidth
                                autoComplete="off"
                            />
                            <Box className={classes.passField}>
                                <TextField
                                    className={classes.marginBottom}
                                    error={passwordError}
                                    helperText={passwordError ? 'Mandatory field' : ''}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    name="psw"
                                    value={password}
                                    onChange={(e) => {
                                        setPasswordError(false);
                                        setPassword(e.target.value);
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                />
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleShowPassword}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                                </IconButton>
                            </Box>
                            {isLoading ? <Loader loading={isLoading} /> : null}
                            <div id="error-message" className={classes.alert_danger} />
                            <div />
                            <button type="submit" className={classes.loginButton} onClick={handleCustomLogin}>Login</button>
                            <a href="/forgot-password" className={classes.forgotPasswordLink}>Forgot Password?</a>
                        </div>
                    </div>
                </div>
                <div className={classes.cright}>
                    <p>
                        Copyright © 2024
                        {' '}
                        <a className={classes.atag} href="https://pcucorp.com/" target="_blank" rel="noreferrer">PCU Corporación SAC / PCU Corporation</a>
                    </p>
                </div>
                <Snackbar open={openErrorBar} autoHideDuration={6000} onClose={() => setOpenErrorBar(false)}>
                    <Alert onClose={() => setOpenErrorBar(false)} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}
