import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import '@sencha/best-react-grid/dist/themes/grui.css';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import AgGridComponent from '../../../../components/agGridTableComponent';
import Loader from '../../../../components/Loader';
import coaQueries from '../../../../queries/coa';
import Pagination from '../../../../components/paginationTable';
import { ColumnDefs } from '../../../../types/AgGridTypes';
import COADifferent from './COADifferent.style';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { useOutletContext } from 'react-router-dom';
import CoaColumn from './CoaColumn';
import { JSONParse } from '../../../../helper/StorageHelper';
import { truthyCodeValidation } from '../../../../helper/validations';

interface ViewsTotalObject {
    labour_hoursTotal: number,
    labourTotal: number,
    distributableTotal: number,
    c_equipmentTotal: number,
    materialTotal: number,
    sustaining_total: number,
    equipmentTotal: number,
    subcontractTotal: number,
    indirectTotal: number,
    total_costTotal: number,
    name: string,
    total_cost: number,
}
type CaseObject = {
    description: string;
    [key: string]: string | number;
  };
interface ViewsDataObject {
    code:string,
    name: string,
    date:string,
    updated_by: string,
    target: {
        value: string,
        checked: boolean
    }
    id: string,
    isCurrentVersion: boolean,
    length: number,
    code1: string,
    code2: string,
    code3: string,
    code4: string,
    code5: string,
    code6: string,
}

interface ViewsDataObjectExtended extends ViewsDataObject {
    [key: string]: string | number | boolean | {
        value: string,
        checked: boolean
    };
}
interface Props {
    view?: string,
    searchText?: string
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

function CoaCaseViews(props: Props) {
    const {
        view, searchText, setSearchText, hideUnusedFlag, setIsFetching,
    } = props;
    const classes = COADifferent();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);
    const [colHeader, setColHeader] = useState<string[]>([]);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    // Function for Total bottom row
    const createCaseData = (params: ViewsTotalObject[]) => {
        const result: CaseObject = {
            description: 'TOTAL',
        };
        for (let i = 0; i < params.length; i += 1) {
            result[`column_${params[i].name}`] = params[i].total_cost && (+(params[i].total_cost) / exchangeRate).toFixed(0);
        }
        return [result];
    };

    // Fetch new page data
    const handlePageChange = (page: number) => {
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    // Fetch API data
    const { data, isFetching, refetch } = useGQLQuery(
        `GetCOACase-${projectCtx?.project?.id || 0}`,
        coaQueries.GET_COA_CASE(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
        {},
        { onError: handleApiError },
    );
    // For COA Case View...
    const caseViewData = data?.coacase;
    const tableCaseData = caseViewData?.data;
    const tableCaseViewData: ViewsDataObject[] = tableCaseData ? JSONParse<ViewsDataObject[]>((tableCaseData).toString()) : [];
    const casePageInfo = caseViewData?.pageInfo;
    const caseTotalValue: ViewsTotalObject[] = caseViewData ? JSONParse<ViewsTotalObject[]>(caseViewData.total_values.toString()) : [];

    // For Bottom Total Value...
    let casePinnedBottomRowData;
    if (caseTotalValue && caseTotalValue.length > 0) {
        casePinnedBottomRowData = createCaseData(caseTotalValue);
    }

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const columnDefs = [
        {
            field: 'code',
            headerName: 'COA',
            cellRenderer: CoaColumn,
            sort: 'asc',
            initialWidth: 120,
            type: 'string',
            initialHide: false,
        },
        {
            field: 'description',
            headerName: 'Account Description',
            cellRenderer: CoaColumn,
            initialWidth: 400,
            type: 'string',
            initialHide: false,
            enableValue: true,
        },
    ];

    useEffect(() => {
        setNewColumnDefs(columnDefs);
    }, [view]);

    const caseTotalPages = (casePageInfo && casePageInfo.totalcount) ? Math.ceil(casePageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    // Add dynamic column for case view
    const caseDynamicCol = () => {
        if (tableCaseViewData && tableCaseViewData.length > 0 && view === 'Case View') {
            setColHeader([]);
            const tempArr: string[] = [];
            Object.keys(tableCaseViewData[0]).forEach((item) => {
                if (item.startsWith('column_')) {
                    const ak = item.split('_')[1].replaceAll('_', ' ');
                    tempArr.push(`${item}`);
                    columnDefs.push(
                        {
                            field: `${item}`,
                            headerName: ak,
                            cellRenderer: CoaColumn,
                            initialWidth: 150,
                            type: 'numericColumn',
                            initialHide: false,
                            enableValue: true,
                        },
                    );
                    setNewColumnDefs(columnDefs);
                }
            });
            setColHeader([...colHeader, ...tempArr]);
        }
    };

    const generateColumnDefs = () => {
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tableCaseViewData?.map((item) => ((item as ViewsDataObjectExtended)[code])))?.some(truthyCodeValidation);
            if (flag) {
                columnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    initialWidth: 120,
                    cellRenderer: CoaColumn,
                    type: 'string',
                    initialHide: flag,
                    enableValue: false,
                });
            }
        });
        setNewColumnDefs(columnDefs);
    };

    useEffect(() => {
        caseDynamicCol();
        generateColumnDefs();
    }, [view, isFetching]);

    useEffect(() => {
        setIsFetching(isFetching);
    }, [isFetching]);

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box className={isFetching ? classes.opacityBlur : ''}>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={tableCaseViewData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={casePinnedBottomRowData}
                    isClipboardEnabled
                    isExportEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isGroupable
                    CoaRowBgStyle
                    moduleName="coa_caseview"
                    colFormat={colHeader}
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        </div>
    );
}

export default CoaCaseViews;
