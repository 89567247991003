import React, { useContext, useState, useEffect } from 'react';
import useStyles from './UnitRateHome.style';
import {
    Box, Button, FormControl, MenuItem, Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CostHeaderBar from '../../../../components/costHeaderBar';
import UnitRateDetailView from '../../Components/UnitRateDetailView';
import UnitRateListView from '../../Components/UnitRateListView';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import SelectDropdown from '../../../../components/DropdownSelect';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import exportExcel from '../../../../helper/ExportExcel';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import { fetchData } from '../../../../hooks/useGQLQuery';
import unitRateQueries from '../../../../queries/unitrates';
import gqlConfig from '../../../../helper/gqlConfig';
import { useDebounce } from '../../../../hooks/useDebounce';
import HideUnusedButton from '../../../../components/HideUnusedButton';

interface CoaCode {
    coa_code1: string;
    coa_code: string;
    coa_description: string;
}

interface CoaCodesResponse {
    distinctCoaCodes: CoaCode[];
}

interface LocationState {
    unitRateId?: string;
}

interface CoaCodeState {
    value: string;
    label: string;
    levelOneCoaCode: string;
}

function UnitRateHomePage() {
    const classes = useStyles();
    const [view, setView] = useState('list');
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState<string>('');
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const cont: OutletContext = useOutletContext();
    const [coaCodes, setCoaCodes] = useState<CoaCodeState[]>([]);
    const [selectedCoaCode, setSelectedCoaCode] = useState<string>('');
    const [selectedCodeOne, setSelectedCodeOne] = useState<string>('');
    const location = useLocation();
    const { unitRateId } = (location.state || {}) as LocationState;
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);
    const query = unitRateQueries.GET_DISTINCT_COA_CODES(
        0,
        1000,
        userCtx?.user?.default_org_id || '',
        projectCtx?.project?.id || '',
        projectCtx?.project?.version_id || '',
    );
    const graphQLClient = gqlConfig('/list');
    const debouncingValue = useDebounce(searchText);
    const isGlobalSearchActive = debouncingValue.length > 0;
    const [isFetching, setIsFetching] = useState(false);
    const [flagForCoa, setFlagForCoa] = useState(false);

    useEffect(() => {
        if (!isGlobalSearchActive && !unitRateId) {
            setFlagForCoa(() => true);
            fetchData(graphQLClient, query, {})
                .then((response: unknown) => {
                    const coaCodeData = (response as CoaCodesResponse).distinctCoaCodes;
                    const levelOneCoaCodes = coaCodeData
                        .map((code) => ({
                            value: code.coa_code,
                            label: `${code.coa_code} - ${code.coa_description}`,
                            levelOneCoaCode: code.coa_code1,
                        }));

                    setCoaCodes(levelOneCoaCodes);

                    if (levelOneCoaCodes.length > 0) {
                        setSelectedCoaCode(levelOneCoaCodes[0].value);
                        setSelectedCodeOne(levelOneCoaCodes[0].levelOneCoaCode);
                    }
                })
                .catch((err: string) => {
                    cont.showNotificationBar(err, 'error');
                })
                .finally(() => {
                    setFlagForCoa(() => false);
                });
        } else {
            setSelectedCoaCode('');
            setSelectedCodeOne('');
            setFlagForCoa(() => true);
            setCoaCodes([]);
        }
    }, [isGlobalSearchActive, unitRateId]);

    const handleChange = (event: SelectChangeEvent) => {
        setSearchText('');
        setHideUnusedFlag(true);
        setView(event.target.value);
    };

    const handleCoaCodeChange = (newCoaCode: string) => {
        if (!isGlobalSearchActive) {
            setSelectedCoaCode(newCoaCode);
            setSelectedCodeOne(coaCodes.find((coaCode) => coaCode.value === newCoaCode)?.levelOneCoaCode || '');
        }
    };

    const handleCoaCodeArrow = (type: string) => {
        if (!isGlobalSearchActive) {
            const coaCodeIndex = coaCodes.findIndex((code) => code.value === selectedCoaCode);
            if (coaCodeIndex === -1) return;
            const newCoaCode = type === 'next' ? coaCodes[coaCodeIndex + 1]?.value : coaCodes[coaCodeIndex - 1]?.value;
            if (newCoaCode) {
                handleCoaCodeChange(newCoaCode);
            }
        }
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=unit rate`);
    };

    const handleReset = () => {
        // Reset condition removed,since we dont have predefined menuOptions
    };

    const handleExportExcel = () => {
        const currGrid = view === 'detail' ? 'unit_rate_detail' : 'unit_rate';
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: currGrid,
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div>
            <div className={classes.TableHeader}>
                <Box className={classes.inTableHeader}>
                    <Typography variant="h2">UNIT RATE</Typography>
                    <CostHeaderBar />
                    <Box className={classes.versonUndo}>
                        <Box className={classes.outlineButton}>
                            <Button onClick={navigateToReports}>
                                <DataSaverOffOutlinedIcon />
                                {' '}
                                Reports
                            </Button>
                        </Box>
                        <FormControl variant="filled">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={view}
                                label="view"
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                color: '#787878',
                                            },
                                            '& .Mui-selected': {
                                                color: '#021948',
                                                bgcolor: '#E6ECFA !important',
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="list">List View</MenuItem>
                                <MenuItem value="detail">Detail View</MenuItem>
                            </Select>
                        </FormControl>
                        {view === 'list' && (
                            <SelectDropdown
                                view={isGlobalSearchActive ? '' : selectedCoaCode}
                                menuOptions={coaCodes}
                                handleChange={handleCoaCodeChange}
                                handleArrow={handleCoaCodeArrow}
                            />
                        )}
                        <QuickSearchFilter setSearchText={setSearchText} searchText={searchText} />
                        {(!isGlobalSearchActive && (view === 'detail' || (!unitRateId && view === 'list'))) && (
                            <HideUnusedButton hideUnusedFlagVal={hideUnusedFlag} setHideUnusedFlag={setHideUnusedFlag} loadingData={isFetching} />
                        )}
                    </Box>
                </Box>
                <Box className={classes.outlineButton}>
                    <Button onClick={handleExportExcel}>
                        <CloudUploadOutlinedIcon />
                        {' '}
                        Export
                    </Button>
                </Box>
            </div>
            <Box>
                {view === 'detail'
                    ? (
                        <UnitRateDetailView
                            setIsFetching={setIsFetching}
                            hideUnusedFlag={hideUnusedFlag}
                            handleReset={handleReset}
                            setSearchText={setSearchText}
                            searchText={searchText}
                        />
                    ) : (
                        <UnitRateListView
                            setIsFetching={setIsFetching}
                            isFetching={isFetching}
                            isGlobalSearchActive={isGlobalSearchActive}
                            hideUnusedFlag={hideUnusedFlag}
                            setSearchText={setSearchText}
                            searchText={searchText}
                            handleReset={handleReset}
                            selectedCoaLabel={coaCodes.find((coaCode) => coaCode.value === selectedCoaCode)?.label}
                            selectedCoaCodeOne={selectedCodeOne}
                            flagForCoa={flagForCoa}
                        />
                    )}
            </Box>
        </div>
    );
}

export default UnitRateHomePage;
