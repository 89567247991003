/* eslint-disable no-param-reassign */
import React, {
    useContext, useMemo, useRef, useEffect,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import Paper from '@mui/material/Paper';
import { Box, styled } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AgGridTableStyle from './AgGridTable.style';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    ColumnDefs, RowData, CellRendererType,
} from '../../types/AgGridTypes';
import {
    GridOptions, GridReadyEvent, ColumnMenuTab, MenuItemDef, ColumnState, ApplyColumnStateParams,
} from 'ag-grid-community';
import sortUpIcon from '../../assets/sortUpIcon.svg';
import sortDownIcon from '../../assets/sortDownIcon.svg';
import { UserContext } from '../../store/context/userContext';
import { JSONParse } from '../../helper/StorageHelper';
import { ResourceType } from 'mapbox-gl';
import { ProjectContext } from '../../store/context/projectContext';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import { useGQLMutation } from '../../hooks/useGQLMutation';
import gridLayoutQueries from '../../queries/gridLayout';
import Loader from '../Loader';

interface GridState {
    state: ColumnState[],
    filter: { [key: string]: any },
}

interface DynamicType {
    [key: string]: string | number,
}

const allMenuTabs: ColumnMenuTab[] = ['generalMenuTab', 'filterMenuTab'];
interface AgGridGroupComponentProps {
    columnDefs: ColumnDefs[]; // Define your column definitions
    rowData: RowData[]; // Define your row data
    sortOrder?: string;
    changeSortingValue: (par: string) => void;
    parentColumn: string;
    groupCellRenderer?: (params: CellRendererType) => void,
    noSelection?: boolean,
    noSorting?: boolean,
    isExportEnabled?: boolean,
    isClipboardEnabled?: boolean,
    isToolPanelsEnabled?: boolean,
    isStatusBarEnabled?: boolean,
    isPinnable?: boolean,
    isGroupable?: boolean,
    moduleName?: string,
    rowSelection?: 'single' | 'multiple',
    isFetching?: boolean
    colFormat?: string[]
    quickFilterText?: string,
    handleReset?: () => void;
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
}

interface CellDataType {
    heirarchy: string[]
}

const Main = styled('main')({
    flexGrow: 1,
    // height: '80vh',
    overflow: 'auto',
});

const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    margin: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
}));

/**
 * Ag-grid component for tree structure data
 * The following points are necessary to render group ag-grid
 *      ** Both parent and child row should have a unique field to identify the row
 *      ** The same field will be used in the heirarchy property to tell ag-grid which rows have parent/child relation
 * Sample Data format
 * rowData = [
 *  {
 *      code: parentCode,
 *      heirarchy: [parentCode]
 *  },
 *  {
 *      code: child1,
 *      heirarchy: [parentCode, child1]
 *  },
 *  {
 *      code: child2,
 *      heirarchy: [parentCode, child2]
 *  },
 * ]
 * @param param0
 * @returns
 */
function AgGridGroupComponent({
    columnDefs,
    rowData,
    sortOrder,
    changeSortingValue,
    parentColumn,
    groupCellRenderer,
    noSelection,
    noSorting,
    isExportEnabled,
    isClipboardEnabled,
    isToolPanelsEnabled,
    isStatusBarEnabled,
    isPinnable,
    isGroupable,
    moduleName,
    rowSelection,
    quickFilterText,
    isFetching,
    colFormat,
    handleReset,
    setSearchText,
}: AgGridGroupComponentProps) {
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const gridRefCommon = useRef<AgGridReact>(null);
    const classes = AgGridTableStyle();
    const activeGridRef = gridRefCommon;
    const activeModuleName = moduleName ? `${moduleName}_${userCtx?.user?.user_id || ''}` : '';
    const customHeaderComponent = (param: CellRendererType) => (
        <div
            role="presentation"
            className="AgHeaderCustom"
            onClick={() => changeSortingValue(param?.column?.colDef?.field)}
        >
            <div className={classes.AgHeaderCustomDesc}>
                {param?.column?.colDef?.headerName}
                {sortOrder === 'DESC' ? (
                    <span>
                        <KeyboardArrowDownIcon />
                    </span>
                ) : (
                    <span>
                        <KeyboardArrowUpIcon />
                    </span>
                )}
            </div>
        </div>
    );

    const { data: newGridLayoutState, isFetching: isNewGridLayoutState, refetch } = useGQLQuery(
        `GetGridLayout-${activeModuleName}`,
        gridLayoutQueries.GET_GRID_LAYOUT(
            activeModuleName,
        ),
        {},
        {},
    );

    const { mutate, isLoading: saveGridLoading } = useGQLMutation(
        'SaveGridLayout',
        gridLayoutQueries.SAVE_GRID_LAYOUT,
        {
            onSuccess: () => refetch(),
        },
        '/list',
    );

    const hanldleReset = () => {
        refetch();
    };

    const { mutate: resetMutate, isLoading: isResetLoading } = useGQLMutation(
        'resetGridLayout',
        gridLayoutQueries.RESET_GRID_LAYOUT,
        {
            onSuccess: hanldleReset,
        },
        '/list',
    );

    // Reloading grid
    const resetGridState = () => {
        if (activeGridRef.current) {
            activeGridRef.current.columnApi?.resetColumnState();
            activeGridRef.current.api?.setFilterModel(null);
            handleReset?.();
            if (setSearchText) {
                setSearchText('');
            }
        }
    };

    // Clear filter
    const clearFilter = () => {
        if (activeGridRef && activeGridRef.current) {
            activeGridRef.current.api?.setFilterModel(null);
        }
    };

    columnDefs.forEach((columnDef) => {
        columnDef.minWidth = 50;
        columnDef.resizable = true;
        if (columnDef.sorting) {
            columnDef.headerComponentFramework = customHeaderComponent;
        }
        switch (columnDef.type) {
        case 'string': columnDef.filter = 'agMultiColumnFilter';
            break;
        case 'numericColumn':
        case 'number': columnDef.filter = 'agMultiColumnFilter';
            columnDef.filterParams = {
                filters: [
                    {
                        filter: 'agNumberColumnFilter',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    },
                ],
            };
            break;
        default: columnDef.filter = 'agSetColumnFilter';
        }
    });

    // COLUMN_DEF for the first column containing checkbox
    const checkedColumn = {
        field: 'blank',
        headerName: '',
        checkboxSelection(params: any) {
            let flag = true;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (params?.data.heirarchy.length === 1) {
                flag = false;
            }
            return flag;
        },
        headerCheckboxSelection: true,
        width: 30,
        maxWidth: 30,
    };
    const gridOptions: GridOptions = {
        rowHeight: 27,
        enableRangeSelection: true,
        enableBrowserTooltips: true,
        enableRangeHandle: true,
        autoGroupColumnDef: {
            headerName: parentColumn,
            headerClass: 'groupParentHeader',
            minWidth: 50,
            resizable: true,
            initialWidth: 200,
            cellRendererParams: {
                suppressCount: true,
            },
            cellRenderer: groupCellRenderer,
            // Sort comparator for ag grid grouped column
            comparator(valueA, valueB) {
                if (valueA === valueB) {
                    return 0;
                }

                return (valueA > valueB) ? 1 : -1;
            },
            filter: 'agMultiColumnFilter',
        },
        treeData: true, // enable Tree Data mode
        animateRows: true,
        groupDefaultExpanded: -1, // expand all groups by default
        getDataPath: (data: CellDataType) => data.heirarchy,
        suppressAggFuncInHeader: true,
        getContextMenuItems: () => {
            const returnArray = [];
            if (isClipboardEnabled) returnArray.push('copy');
            if (isClipboardEnabled) returnArray.push('copyWithHeaders');
            if (isExportEnabled) returnArray.push('export');
            return returnArray;
        },
        getMainMenuItems: (param) => {
            const returnArray: (string | MenuItemDef)[] = [];
            if (isPinnable) returnArray.push('pinSubMenu');
            if (isGroupable) returnArray.push('rowGroup');
            if (isPinnable || isGroupable) returnArray.push('separator');
            returnArray.push('autoSizeThis');
            returnArray.push('autoSizeAll');
            returnArray.push('separator');
            returnArray.push({
                name: 'Sort Ascending',
                action: () => {
                    const columnState: ApplyColumnStateParams = {
                        state: [
                            {
                                colId: param.column.getColId(),
                                sort: 'asc',
                            },
                        ],
                    };
                    activeGridRef.current?.columnApi.applyColumnState(columnState);
                },
            });
            returnArray.push({
                name: 'Sort Descending',
                action: () => {
                    const columnState: ApplyColumnStateParams = {
                        state: [
                            {
                                colId: param.column.getColId(),
                                sort: 'desc',
                            },
                        ],
                    };
                    activeGridRef.current?.columnApi.applyColumnState(columnState);
                },
            });
            returnArray.push({
                name: 'Reset Sort',
                action: () => {
                    const columnState: ApplyColumnStateParams = {
                        state: [
                            {
                                colId: param.column.getColId(),
                                sort: null,
                            },
                        ],
                    };
                    activeGridRef.current?.columnApi.applyColumnState(columnState);
                },
            });
            if (activeModuleName) {
                returnArray.push('separator');
                returnArray.push({
                    name: 'Save Grid Layout',
                    action: () => {
                        const stateTobeStored: GridState = {
                            state: param.columnApi.getColumnState(),
                            filter: param.api.getFilterModel(),
                        };
                        mutate({
                            module_name: activeModuleName,
                            grid_state: JSON.stringify(stateTobeStored),
                        });
                    },
                });
            }
            if (activeModuleName) {
                returnArray.push('separator');
                returnArray.push({
                    name: 'Reset Columns',
                    action: () => {
                        // refetch();
                        resetGridState();
                        resetMutate({
                            module_name: activeModuleName,
                        });
                    },
                });
                returnArray.push({
                    name: 'Clear Filters',
                    action: () => {
                        clearFilter();
                    },
                });
            }
            return returnArray;
        },
        sideBar: isToolPanelsEnabled ? {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225,
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250,
                },
            ],
        } : null,
        statusBar: isStatusBarEnabled ? {
            statusPanels: [
                {
                    statusPanel: 'agAggregationComponent',
                    align: 'right',
                },
            ],
        } : undefined,
        rowSelection,
    };

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;

    // Function to Multiply table data with Exchange rate in Project related pages
    let updatedRowData: RowData[] = [];
    if (colFormat && colFormat.length && exchangeRate && rowData && rowData.length) {
        updatedRowData = rowData?.map((obj) => {
            const newObj = {};
            Object.keys(obj).forEach((key) => {
                if (colFormat.includes(key)) {
                    if (key === 'total_cost' || key === 'total') {
                        (newObj as DynamicType)[key] = +((+((obj as DynamicType)[key]) / exchangeRate).toFixed(0));
                    } else {
                        (newObj as DynamicType)[key] = +((+((obj as DynamicType)[key]) / exchangeRate).toFixed(2));
                    }
                } else {
                    (newObj as DynamicType)[key] = (obj as DynamicType)[key];
                }
            });
            return newObj;
        });
    }

    const tableData = colFormat ? updatedRowData : rowData;

    const menuTabs = allMenuTabs.slice(isPinnable || isGroupable || activeModuleName ? 0 : 1);

    const defaultColDef = useMemo(() => ({
        menuTabs,
        sortable: !noSorting,
        filter: true,
        // unSortIcon: !noSorting,
        icons: {
            sortAscending: () => `<img src="${sortUpIcon}" width="10" height="10" alt="Asc" class="custom-icon" />`,
            sortDescending: () => `<img src="${sortDownIcon}" width="10" height="10" alt="Desc" class="custom-icon" />`,
        },
        enableValue: true,
        enablePivot: true,
        enableRowGroup: true,
    }), []);

    /**
     * Function to shift the parent group column at the start
     * @param params
     */
    const onDataUpdate = (params: GridReadyEvent) => {
        const { columnApi } = params;
        columnApi.moveColumns(['blank'], 0);
        if (activeGridRef && activeGridRef.current && activeGridRef.current?.columnApi) {
            activeGridRef.current?.columnApi.autoSizeAllColumns();
        }
    };

    const getGridObject = () => {
        const gridStateObj = JSONParse<GridState>(newGridLayoutState?.getGridLayout?.data?.grid_state as string | undefined);
        if (newGridLayoutState) {
            // if (gridStateObj?.state) {
            //     activeGridRef.current?.columnApi?.applyColumnState({
            //         state: gridStateObj?.state,
            //         applyOrder: true,
            //     });
            // }
            return gridStateObj;
        }
        return null;
    };

    const applyGridState = (gridStateObj: GridState | null) => {
        if (gridStateObj?.state) {
            activeGridRef.current?.columnApi?.applyColumnState({
                state: gridStateObj?.state,
                applyOrder: true,
            });
        }
    };
    const applyGridStateFilter = (gridStateObj: GridState | null) => {
        if (gridStateObj?.filter) {
            activeGridRef.current?.api?.setFilterModel(gridStateObj?.filter);
        }
    };

    useEffect(() => {
        if (!isNewGridLayoutState && newGridLayoutState) {
            // resetGridState();
            const getGridObjectRes = getGridObject();
            applyGridState(getGridObjectRes);
            applyGridStateFilter(getGridObjectRes);
        }
    }, [isNewGridLayoutState, newGridLayoutState]);

    const columnDefWithDefault = useMemo(() => (noSelection ? columnDefs : [checkedColumn, ...columnDefs]), [columnDefs]);

    const onGridReady = () => {
        const getGridObjectRes = getGridObject();
        applyGridState(getGridObjectRes);
        applyGridStateFilter(getGridObjectRes);
    };

    return (

        <Box>
            <Main>
                <Item>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <div
                            className={`ag-theme-alpine ${classes.tableStyles}`}
                            style={{ height: '400px', width: '100%' }}
                        >
                            {(saveGridLoading || isResetLoading) && (<Loader loading={saveGridLoading || isResetLoading} isFromAGGrid />)}
                            <AgGridReact
                                rowData={tableData}
                                gridOptions={gridOptions}
                                ref={activeGridRef}
                                defaultColDef={defaultColDef}
                                onRowDataUpdated={onDataUpdate}
                                groupIncludeFooter={false}
                                columnDefs={columnDefWithDefault}
                                onFirstDataRendered={onGridReady}
                                suppressDragLeaveHidesColumns
                                quickFilterText={quickFilterText}
                                onGridReady={onGridReady}
                            />
                        </div>
                    </Box>
                </Item>
            </Main>
        </Box>
    );
}

export default AgGridGroupComponent;
