import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';
import { ColumnVO } from 'ag-grid-community';

const GET_UNIT_RATE_BY_ORGID = ({ org_id }: { org_id: string }) => gql`
query GetUnitRateDataByOrgId {
    unit_rate(where: {org_id: {_eq: "${org_id}"}}) {
      class
      sequence
      description
      productivity
      unit
    }
  }
`;

const GET_UNIT_RATE_DATA = (skip: number, take: number, sort: string, sortOrder: string) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
    query GetUnitRateData {
        unitrate(skip: ${skip}, take: ${take}, ${sortType}) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          }
          data {
            id
            org_id
            project_id
            coa_id
            generic_coa_id
            productivity
            code
            description
            unit
            unit_man_hours
            unit_labour_cost
            unit_consequip_cost
            unit_material_cost
            unit_equipment_cost
            unit_subcontract_cost
            unit_indirect_cost
            input_rate
            created_at
            updated_at
            labour
            total_cost
            total_unit_cost
            unit_cost {
              sub_code
              sub_description
              quantity
            }
          }
        }
      }
    `;
};

const GET_UNIT_RATE_INFO = (skip: number, take: number, orgId: string, projId: string, verId: string, sort?: boolean) => gql`
    query GetUnitRateInfo {
        unitrate(skip: ${skip}, take: ${take}, filter:{org_id: ${addEscapeCharacters(orgId)},
        project_id: ${addEscapeCharacters(projId)}, version_id: ${addEscapeCharacters(verId)}},
        ${sort ? 'sort: {cost_in_estimate: DESC}' : ''}) {
          pageInfo {
            skip
            take
            hasNextPage
            totalcount
          },
          data {
            id
            coa_code
            generic_coa_id
            productivity
            code
            description
            unit
            unit_man_hours
            unit_labour_cost
            unit_consequip_cost
            unit_material_cost
            unit_equipment_cost
            unit_subcontract_cost
            unit_indirect_cost
            created_at
            updated_at
            labour
            quantity_in_estimate
            total_cost
            total_unit_cost
            total_quantity
            unit_cost
            cost_in_estimate
          }
        }
      }
    `;

// the sort hard coded is as per clients requirement in UAT feed back and should be removed in next phase
// Query For fetching Unit rate data for list view
export interface SortModel {
  colId: string;
  sort: 'asc' | 'desc';
}

const serializeSortModel = (sortModel?: SortModel[] | []) => {
    if (!sortModel || sortModel.length === 0) {
        return 'sortModel: []';
    }
    const serializedItems = sortModel.map(
        (item) => `{ colId: "${item.colId}", sort: "${item.sort}" }`,
    );
    return `sortModel: [${serializedItems.join(', ')}]`;
};

const GET_UNIT_RATE_LIST_VIEW_DATA = (
    skip: number,
    take: number,
    orgId: string,
    projId: string,
    verId: string,
    unitRateId: string,
    hideUnusedFlag?: boolean,
    searchText?: string,
    currencyExchangeRate?: number,
    filterModel?: string,
    sortModel?: SortModel[] | [],
    groupKeys?: string[],
    rowGroupCols?: string,
    selectedCoaCode?: string,
    valueCols?: string,
) => {
    const id = unitRateId ? `id:${addEscapeCharacters(unitRateId)},` : '';
    const filterModelParam = filterModel ? `filterModel: ${JSON.stringify(filterModel)}` : 'filterModel:"{}"';
    const rowGroupColsParam = rowGroupCols ? `rowGroupCols: ${JSON.stringify(rowGroupCols)}` : 'rowGroupCols:"[]"';
    const valueColsParam = valueCols ? `valueCols: ${JSON.stringify(valueCols)}` : 'valueCols:"[]"';
    const serializedSortModel = serializeSortModel(sortModel);
    const searchTextParam = searchText ? `searchText: ${JSON.stringify(searchText)}` : 'searchText: ""';
    const groupKeysParam = groupKeys && groupKeys.length > 0 ? `groupKeys: ["${groupKeys.join('","')}"]` : 'groupKeys: []';
    const selectedCoaCodeParam = selectedCoaCode ? `coa_code1: "${selectedCoaCode}"` : '';
    return gql`
    query GetUnitRateListViewData {
      unitrateListView(
        filter: {
          org_id: ${addEscapeCharacters(orgId)},
          project_id: ${addEscapeCharacters(projId)},
          version_id: ${addEscapeCharacters(verId)},
          ${id}
          ${selectedCoaCodeParam}
        },
        currencyExchangeRate: ${currencyExchangeRate},
        ${searchTextParam},
        ${filterModelParam},
        skip: ${skip},
        take: ${take},
        hide_unused_flag: ${!!hideUnusedFlag},
        ${serializedSortModel},
        ${groupKeysParam},
        ${rowGroupColsParam},
        ${valueColsParam},
      ) {
        data {
          id
          coa_code
          coa_description
          code
          description
          unit
          unit_man_hours
          unit_labour_cost
          labour
          unit_material_cost
          unit_consequip_cost
          lab_rate
          quantity_in_estimate
          cost_in_estimate
          total_unit_cost
          unit_cost
          total_quantity
        }
      }
    }
  `;
};

const GET_UNIT_RATE_DETAIL_DATA = (
    skip: number,
    take: number,
    sort: string,
    sortOrder: string,
    orgId: string,
    projId: string,
    verId: string,
    hideUnusedFlag?: boolean,
) => {
    const sortType = sort === '' ? '' : `sort: {${sort}:${sortOrder}}`;
    return gql`
      query GetUnitRateDetailData {
          unitrate(filter: {
            org_id: ${addEscapeCharacters(orgId)},
            project_id: ${addEscapeCharacters(projId)},
            version_id: ${addEscapeCharacters(verId)},
          }
          skip: ${skip}, take: ${take}, ${sortType}
          sort: { coa_code: ASC, code: ASC, entity_code: ASC },
          hide_unused_flag: ${hideUnusedFlag}
          )
          {
            pageInfo {
              skip
              take
              hasNextPage
              totalcount
            }
            data {
              coa_code
              code
              description
              unit
              labour
              unit_man_hours
              quantity_in_estimate
              unit_cost_data {
                entity_code
                entity_unit
                sub_description
                entity_cost
                quantity
                total_quantity
                labour_hours
                labour
                material
                equipment
                p_equipment
                subcontract
                total_unit_resource
                entity_description
              }
            }
          }
        }
      `;
};

const GET_DISTINCT_COA_CODES = (skip: number, take: number, orgId: string, projId: string, verId: string) => gql`
    query GetDistinctCoaCodes {
      distinctCoaCodes(skip: ${skip}, take: ${take}, filter: {
        org_id: "${orgId}",
        project_id: "${projId}",
        version_id: "${verId}"
      }) {
        coa_code1
        coa_code
        coa_description
      }
    }
`;

const GET_UNIT_COST_BY_UNIT_RATE_ID = (skip: number, take: number, orgId: string, projId: string, verId: string, unitRateId: string) => gql`
  query GetUnitCostByUnitRateId {
    unitCostByUnitRateId(skip: ${skip}, take: ${take}, filter: {
        org_id: "${orgId}",
        project_id: "${projId}",
        version_id: "${verId}",
        unit_rate_id: "${unitRateId}",
      }) {
      unit_cost_data {
        id
        entity_code
        entity_type
        entity_id
        entity_unit
        sub_description
        entity_cost
        quantity
        total_quantity
        labour_hours
        labour
        material
        equipment
        p_equipment
        subcontract
        total_unit_resource
        entity_description
        resource_type
      }
    }
  }
`;

const GET_UNIT_RATE_BY_COA_CODE = (
    coaCode: string,
    orgId: string,
    projId: string,
    verId: string,
    skip: number,
    take: number,
) => gql`
  query GetUnitRateByCoaCode {
      unitrate(
          filter: {
              org_id: ${addEscapeCharacters(orgId)},
              project_id: ${addEscapeCharacters(projId)},
              version_id: ${addEscapeCharacters(verId)}
          },
          skip: ${skip},
          take: ${take}
          coa_code: ${addEscapeCharacters(coaCode)},
      ) {
          data {
            id
            coa_code
            coa_description
            code
            description
            unit
            unit_man_hours
            unit_labour_cost
            labour
            unit_material_cost
            unit_consequip_cost
            lab_rate
            quantity_in_estimate
            cost_in_estimate
            total_unit_cost
            unit_cost
            total_quantity
          }
      }
  }
`;

export default {
    GET_UNIT_COST_BY_UNIT_RATE_ID,
    GET_DISTINCT_COA_CODES,
    GET_UNIT_RATE_BY_COA_CODE,
    GET_UNIT_RATE_BY_ORGID,
    GET_UNIT_RATE_DATA,
    GET_UNIT_RATE_INFO,
    GET_UNIT_RATE_DETAIL_DATA,
    GET_UNIT_RATE_LIST_VIEW_DATA,
};
