import {
    PATH_AUTH, PATH_CMFR, PATH_DATABASE, PATH_PAGE, ROLE_RESTRICTED_CONFIG,
} from '../../Routes/path';
import industryIcon from '../../assets/industory.svg';
import commo from '../../assets/commo.svg';
import region from '../../assets/region.svg';
import projectIcon from '../../assets/proj.svg';
import reportsIcon from '../../assets/reports1Icon.svg';
import dashboardIcon from '../../assets/dashboard.svg';
import orgIcon from '../../assets/organisation.svg';

const isVisible = (path: string) => {
    const { admin, super_admin: superAdmin, user } = ROLE_RESTRICTED_CONFIG;
    return !(superAdmin.includes(path) || admin.includes(path) || user.includes(path));
};

const appDrawerMenuConfig = [
    {
        title: 'Database',
        visible: true,
        submenu: [
            {
                title: 'Organisations',
                icon: orgIcon,
                path: PATH_DATABASE.organisations,
                visible: true,
            },
            // {
            //     title: 'Reports',
            //     icon: reportsIcon,
            //     path: PATH_DATABASE.reports,
            //     visible: isVisible(PATH_DATABASE.reports),
            // },
            {
                title: 'Dashboard',
                icon: dashboardIcon,
                path: PATH_DATABASE.dashboard,
                visible: isVisible(PATH_DATABASE.dashboard),
            },
            {
                title: 'Industries',
                icon: industryIcon,
                path: PATH_DATABASE.industries,
                visible: isVisible(PATH_DATABASE.industries),
            },
            {
                title: 'Commodities',
                icon: commo,
                path: PATH_DATABASE.commodities,
                visible: isVisible(PATH_DATABASE.commodities),
            },
            {
                title: 'Projects',
                icon: projectIcon,
                path: PATH_DATABASE.projects,
                visible: isVisible(PATH_DATABASE.projects),
            },
        ],
    },
];

export const appDrawerSubmenuConfig = [
    {
        title: 'Coal Mines Facilities Relocation',
        visible: true,
        submenu: [
            {
                title: 'CAPEX',
                path: PATH_CMFR.capex.root,
                visible: isVisible(PATH_CMFR.capex.root),
                items: [
                    {
                        title: 'Project Dashboard',
                        path: PATH_DATABASE.projectDashboard,
                        visible: isVisible(PATH_DATABASE.projectDashboard),
                    },
                    // {
                    //     title: 'Reports',
                    //     path: PATH_CMFR.capex.reports,
                    //     visible: isVisible(PATH_CMFR.capex.reports),
                    // },
                    {
                        title: 'WBS',
                        path: PATH_CMFR.capex.wbs,
                        visible: isVisible(PATH_CMFR.capex.wbs),
                    },
                    {
                        title: 'COA',
                        path: PATH_CMFR.capex.coa,
                        visible: isVisible(PATH_CMFR.capex.coa),
                    },
                    {
                        title: 'Packages',
                        path: PATH_CMFR.capex.packages,
                        visible: isVisible(PATH_CMFR.capex.packages),
                    },
                    {
                        title: 'Estimate',
                        path: PATH_CMFR.capex.estimate,
                        visible: isVisible(PATH_CMFR.capex.estimate),
                    },
                    {
                        title: 'Unit Rate',
                        path: PATH_CMFR.capex.unit,
                        visible: isVisible(PATH_CMFR.capex.unit),
                    },
                    {
                        title: 'Resources',
                        path: PATH_CMFR.capex.resources,
                        visible: isVisible(PATH_CMFR.capex.resources),
                    },
                    {
                        title: 'Schedule',
                        path: PATH_CMFR.capex.schedule,
                        visible: isVisible(PATH_CMFR.capex.schedule),
                    },
                    {
                        title: 'Settings',
                        path: PATH_CMFR.capex.settings.root,
                        visible: true,
                        items: [
                            {
                                title: 'General',
                                path: PATH_CMFR.capex.settings.general,
                                visible: isVisible(PATH_CMFR.capex.settings.general),
                                isHashLink: true,
                                hashLinkTo: PATH_CMFR.capex.settings.home,
                            },
                            {
                                title: 'Version',
                                path: PATH_CMFR.capex.settings.general,
                                visible: isVisible(PATH_CMFR.capex.settings.version),
                                isHashLink: true,
                                hashLinkTo: PATH_CMFR.capex.settings.version,
                            },
                            {
                                title: 'Structure',
                                path: PATH_CMFR.capex.settings.general,
                                visible: isVisible(PATH_CMFR.capex.settings.structure),
                                isHashLink: true,
                                hashLinkTo: PATH_CMFR.capex.settings.structure,
                            },
                            {
                                title: 'Currency',
                                path: PATH_CMFR.capex.settings.general,
                                visible: isVisible(PATH_CMFR.capex.settings.currency),
                                isHashLink: true,
                                hashLinkTo: PATH_CMFR.capex.settings.currency,
                            },
                            {
                                title: 'Location',
                                path: PATH_CMFR.capex.settings.general,
                                visible: isVisible(PATH_CMFR.capex.settings.location),
                                isHashLink: true,
                                hashLinkTo: PATH_CMFR.capex.settings.location,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const SUPER_ADMIN_ROUTES = [PATH_DATABASE.organisations];

export const PRE_LOGIN_ROUTES = [PATH_AUTH.customLogin,
    PATH_AUTH.signup, PATH_AUTH.login, PATH_AUTH.forgotPassword, PATH_PAGE.contactAdmin, PATH_PAGE.invitationAccepted];

export default appDrawerMenuConfig;
