import wbsQuery from '../../queries/wbs';
import { DEFAULT_PAGE_SIZE, DEFAULT_REPORT_TAKE_VALUE } from '../../constants';
import packageQuery from '../../queries/packages';
import estimateQueries from '../../queries/estimate';
import resourcesQuery from '../../queries/resources';
import unitrateQuery from '../../queries/unitrates';
import scheduleQuery from '../../queries/schedule';
import projectInfoQuery from '../../queries/projectInfo';
import { GET_CURRENCY } from '../../queries/projectCurrency';
import projectPhaseQuery from '../../queries/phase';
import porjectCaseQuery from '../../queries/case';
import labourQuery from '../../queries/labourAndCrew';
import coaQuery from '../../queries/coa';
import projectQueryVersion from '../../queries/version';
import { GET_ALL_INDUSTRY, GET_INDUSTRIES } from '../../queries/industries';
import commoditiesQuery from '../../queries/commodities';
import projectRecentQuery from '../../queries/DashboardRecentProject';
import { GET_REGION } from '../../queries/region';
import projectQuery from '../../queries/projects';

interface ReportQueryDetails {
    query: string,
    jsPath: string,
    operationName?: string,
    endpoint?: string,
}

const getData = (data: string) => {
    if (data.includes('wbs_info')) return 'wbs_info';
    if (data.includes('wbs')) return 'wbs';
    if (data.includes('coa_info')) return 'coa_info';
    if (data.includes('coa')) return 'coa';
    if (data.includes('packages')) return 'packages';
    if (data.includes('estimate')) return 'estimate';
    if (data.includes('resources')) return 'resources';
    if (data.includes('unit_rate')) return 'unit_rate';
    if (data.includes('unit_cost')) return 'unit_cost';
    if (data.includes('schedule')) return 'schedule';
    if (data.includes('project_info')) return 'project_info';
    if (data.includes('project_version')) return 'project_version';
    if (data.includes('project_case')) return 'project_case';
    if (data.includes('project_phase')) return 'project_phase';
    if (data.includes('project_currency')) return 'project_currency';
    if (data.includes('labour_&_crew_rates')) return 'labour_&_crew_rates';
    if (data.includes('industries_info')) return 'industries_info';
    if (data.includes('industries')) return 'industries';
    if (data.includes('commodities')) return 'commodities';
    if (data.includes('recent_projects')) return 'recent_projects';
    if (data.includes('projects')) return 'projects';
    if (data.includes('regions_info')) return 'regions_info';
    return '';
};

const getQueryDetails = (datasetName: string, orgId: string, projectId: string, versionId: string, exchangeRate: number) => {
    const datasetFormatted = getData(datasetName.toLocaleLowerCase());
    switch (datasetFormatted) {
    case 'wbs':
    case 'wbs_info':
        return {
            module: 'WBS',
            query: wbsQuery.GET_WBS_INFO(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                false,
            ),
            jsPath: '.data.wbs.data.*',
        };
    case 'coa':
    case 'coa_info':
        return {
            module: 'COA',
            query: coaQuery.GET_COA_INFO(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                projectId,
                versionId,
                false,
            ),
            jsPath: '.data.coa.data.*',
        };
    case 'packages':
        // Projects data
        return {
            module: 'Packages',
            query: packageQuery.GET_PACKAGES(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                false,
            ),
            jsPath: '.data.package.data.packageData.*',
        };
    case 'estimate':
        // Estimates data
        return {
            module: 'Estimate',
            query: estimateQueries.GET_ESTIMATE_DETAILS(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                '',
                '',
                '',
                '',
                '[]',
                '[]',
                exchangeRate,
            ),
            jsPath: '.data.estimate_detail.data.estimatedData.*',
        };
    case 'resources':
        // Resources data
        return {
            module: 'Resources',
            query: resourcesQuery.GET_RESOURCES_INFO(
                '',
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                '',
                false,
                false,
            ),
            jsPath: '.data.resource.data.*',
        };
    case 'unit_rate':
        // Unit Rate data
        return {
            module: 'Unit_Rate',
            query: unitrateQuery.GET_UNIT_RATE_LIST_VIEW_DATA(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                '',
                false,
                '',
                exchangeRate,
            ),
            jsPath: '.data.unitrateListView.data.*',
        };
    case 'unit_cost':
        // Unit Cost data
        return {
            module: 'Unit_Cost',
            query: unitrateQuery.GET_UNIT_RATE_DETAIL_DATA(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                projectId,
                versionId,
                false,
            ),
            jsPath: '.data.unitrate.data[*].unit_cost_data.*',
        };
    case 'schedule':
        // Schedule data
        return {
            module: 'Schedule',
            query: scheduleQuery.GET_SCHEDULE_BY_ORGID(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                orgId,
                projectId,
                versionId,
                false,
            ),
            jsPath: '.data.schedule.data.scheduleData.*',
        };
    case 'project_info':
        return {
            endpoint: '/setting',
            module: 'Project_Info',
            query: projectInfoQuery.GET_PROJECTIFO_BY_ID(
                projectId,
                orgId,
            ),
            jsPath: '.data.project_setting',
        };
    case 'project_version':
        return {
            endpoint: '/setting',
            module: 'Project_Version',
            query: projectQueryVersion.GET_PROJECT_VERSION(
                orgId,
                projectId,
            ),
            jsPath: '.data.version.*',
        };
    case 'project_case':
        return {
            endpoint: '/setting',
            module: 'Project_Case',
            query: porjectCaseQuery.GET_PROJECT_CASE(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.case.*',
        };
    case 'project_phase':
        return {
            endpoint: '/setting',
            module: 'Project_phase',
            query: projectPhaseQuery.GET_PROJECT_PHASE(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.phase.*',
        };
    case 'project_currency':
        return {
            endpoint: '/setting',
            module: 'Project_Currency',
            query: GET_CURRENCY(
                orgId,
                projectId,
            ),
            jsPath: '.data.getprojectCurrency.*',
        };
    case 'labour_&_crew_rates':
        return {
            module: 'Labour_&_Crew_Rates',
            query: labourQuery.GET_LABOUR_AND_CREW_INFO(
                orgId,
                projectId,
                versionId,
            ),
            jsPath: '.data.labour_crew_rates.data.*',
        };
    case 'industries':
        return {
            query: GET_ALL_INDUSTRY(
                0,
                DEFAULT_PAGE_SIZE,
                orgId,
                false,
            ),
            jsPath: '.data.all_industries.data.*',
        };
    case 'industries_info':
        return {
            module: 'Industries_info',
            query: GET_INDUSTRIES(orgId),
            jsPath: '.data.user_industries.*',
        };
    case 'commodities':
        return {
            query: commoditiesQuery.GET_COMMODITIES_WITH_PROJECTS_BY_ORGID(
                orgId,
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                false,
            ),
            jsPath: '.data.commodities_detail.data.*',
        };
    case 'projects':
        return {
            module: 'Projects',
            query: projectQuery.GET_PROJECTS(
                0,
                DEFAULT_REPORT_TAKE_VALUE,
                '',
                '',
                orgId,
                '',
                '',
            ),
            jsPath: '.data.project.data.*',
        };
    case 'recent_projects':
        return {
            module: 'Recent_Projects',
            query: projectRecentQuery.GET_RECENT_PROJECTS(0, DEFAULT_REPORT_TAKE_VALUE, '', orgId),
            jsPath: '.data.project.data.*',
        };
    case 'regions_info':
        return {
            module: 'Regions_info',
            query: GET_REGION(orgId),
            jsPath: '.data.regions.*',
        };
    default:
        return {
            query: '',
            jsPath: '',
            operationName: '',
        };
    }
};

const getQueryDetailsByDataset = (orgId: string, projectId: string, versionId: string, datasetName: string, exchangeRate: number) => {
    const item = getQueryDetails(datasetName, orgId, projectId, versionId, exchangeRate) as ReportQueryDetails;
    const endpoint = item.endpoint ? item.endpoint : '/list';
    const body = {
        operationName: item.operationName,
        query: item.query,
    };
    return `uri=${endpoint};jpath=$${item.jsPath};method=POST;body=${JSON.stringify(body)};`
    + 'Header$Content-Type=application/json;';
};

export default getQueryDetailsByDataset;
