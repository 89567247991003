import React, {
    useState,
} from 'react';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    Snackbar,
    Alert,
    IconButton,
} from '@mui/material';
import logo from '../../../../assets/logo.svg';
import { useStyles } from './signup.style';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import userInvites from '../../../../queries/invite';
import InvitationType from '../../../../types/InvitationTypes';
import Loader from '../../../../components/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { PATH_PAGE } from '../../../../Routes/path';
import { usernameValidation } from '../../../../helper/validations';
import PasswordChecklist from 'react-password-checklist';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Signup() {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({
        username: '',
        password: false,
    });
    const [openErrorBar, setOpenErrorBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const organization = query.get('organization');
    const invitation = query.get('invitation');
    const organizationName = query.get('organization_name');
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();

    const handleAcceptInviteSuccess = (data: InvitationType) => {
        if (data.acceptInvitation && data.acceptInvitation.error_code) {
            // Show error message in toaster
            setErrorMessage(data.acceptInvitation.error);
            setOpenErrorBar(true);
        } else {
            // Re-direct to auth0 login
            // loginWithRedirect({
            //     authorizationParams: {
            //         organization: organization || '',
            //         invitation: invitation || '',
            //     },
            //     appState: {
            //         returnTo: '/dashboard',
            //     },
            // })
            //     .then((res) => console.log(res))
            //     .catch((err) => console.log(err));

            navigate(PATH_PAGE.invitationAccepted);
        }
    };

    const handleAcceptInviteFailure = () => {
        // Show error message in toaster
        setErrorMessage('Unable to accept invite');
        setOpenErrorBar(true);
    };

    const { mutate, isLoading } = useGQLMutation(
        'AcceptInvite',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-useless-escape
        userInvites.ACCEPT_INVITATION(
            'signup',
            invitation || '',
            organization || '',
            email || '',
            password,
            username,
        ),
        {
            onSuccess: handleAcceptInviteSuccess,
            onError: handleAcceptInviteFailure,
        },
        '/checkinvitation',
    );

    const handleAcceptInvite = () => {
        // Username and Password validation
        const isUsernameValid = usernameValidation(username);
        const isPasswordValid = error.password;
        if (!password || !isPasswordValid || !username || !isUsernameValid) {
            let usernameError = '';
            if (!username) {
                usernameError = 'Mandatory field';
            }

            if (!isUsernameValid) {
                usernameError = 'Maximum 64 characters allowed';
            }

            // Bug Fix PC2-I81 apply correct error value for password
            setError({
                username: usernameError,
                password: !!password,
            });
        } else {
            mutate({});
        }
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Box component="div" />
            <Grid item xs={12} sm={12} className={classes.headerBackground}>
                <Grid container spacing={0} sx={{ margin: 'auto', padding: '0px 150px' }}>
                    <Box><img src={logo} alt="logo" style={{ margin: '10px 0px' }} /></Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={0}
                direction="row"
                sx={{
                    minHeight: '90vh', margin: 'auto', padding: '0px 150px', alignItems: 'center', justifyContent: 'center',
                }}
                className={classes.wrapper}
            >
                <Grid item xs={12} sm={8}>
                    <Typography variant="h2" component="h2" className={classes.pageTitle}>PCU System</Typography>
                    <Typography variant="body2" className={classes.pageDescription}>
                        Global Cost Database and Estimating Platform for Engineering Professionals, Project Managers, and Cost Analysts
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.loginContent}>
                    <div className={classes.loginBox}>
                        <div className={classes.centerDiv}>
                            <h2>Accept your invitation to signup | PCU System</h2>
                            <p>
                                Sign Up to accept invitation to join
                                {' '}
                                <Tooltip title={organizationName}>
                                    <span>{organizationName}</span>
                                </Tooltip>
                            </p>
                        </div>
                        <TextField
                            fullWidth
                            margin="dense"
                            placeholder="Email"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={email}
                            disabled
                            className={classes.disabledTextField}
                            autoComplete="off"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            placeholder="Username"
                            variant="outlined"
                            value={username}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.username}
                            helperText={error.username}
                            onChange={(e) => {
                                setError({
                                    ...error,
                                    username: '',
                                });
                                setUsername(e.target.value);
                            }}
                            autoComplete="off"
                        />
                        <Box className={classes.passField}>
                            <TextField
                                fullWidth
                                margin="dense"
                                placeholder="Password"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={password}
                                onChange={(e) => {
                                    // setError({
                                    //     ...error,
                                    //     password: '',
                                    // });
                                    setPassword(e.target.value);
                                }}
                                // helperText={error.password ? 'Mandatory field' : ''}
                                autoComplete="off"
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPassword}
                            >
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
                            </IconButton>
                        </Box>
                        <PasswordChecklist
                            className={classes.passwordValid}
                            rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                            minLength={8}
                            value={password}
                            onChange={(isValid) => {
                                setError({
                                    ...error,
                                    password: isValid,
                                });
                            }}
                            iconSize={11}
                        />
                        {isLoading ? <Loader loading={isLoading} /> : null}
                        <Button
                            style={{ textTransform: 'capitalize' }}
                            className={classes.loginBtn}
                            onClick={handleAcceptInvite}
                            variant="contained"
                            fullWidth
                            disabled={(!username) || (!password) || (!!error.username) || (!error.password)}
                        >
                            Continue
                        </Button>
                    </div>
                    <div className={classes.copyrightText}>
                        <p>
                            Copyright © 2024
                            {' '}
                            <a className={classes.atag} href="https://pcucorp.com/" target="_blank" rel="noreferrer">PCU Corporación SAC / PCU Corporation</a>
                        </p>
                    </div>
                    <Snackbar open={openErrorBar} autoHideDuration={6000} onClose={() => setOpenErrorBar(false)}>
                        <Alert onClose={() => setOpenErrorBar(false)} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </>
    );
}

export default Signup;
